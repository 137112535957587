<template>
  <div>
    <input class="form-control" type="file" @change="uploadFile( $event )">
    <div v-if="fileUploadPercentage !== 0" class="progress mt-1">
      <div class="progress-bar" role="progressbar" v-bind:style="{ width: fileUploadPercentage + '%'}"
           :aria-valuenow="fileUploadPercentage" aria-valuemin="0" aria-valuemax="100">
        {{ fileUploadPercentage }}%
      </div>
    </div>
    <div class="form-text">{{ this.fileUploadStatus }}</div>
  </div>
</template>

<script>
import {getAxios} from "../store/api";

export default {
  name: "FileUpload",

  data() {
    return {
      file: '',
      fileUploadStatus: '',
      fileUploadPercentage: 0
    }
  },

  methods: {
    uploadFile(event) {
      this.fileUploadPercentage = 0;
      this.file = event.target.files[0];

      let formData = new FormData();

      formData.append('file', this.file);

      this.fileUploadStatus = "Bitte warten..."

      getAxios().post('/file',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            },
            onUploadProgress: function (progressEvent) {
              this.fileUploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
            }.bind(this)
          }
      ).then(response => {
        this.fileUploadStatus = "Erfolgreich hochgeladen :)"
        this.$emit("uploadFinished", {fileId: response.data.id})
      }).catch(() => {
        this.fileUploadStatus = "Datei konnte nicht hochgeladen werden :("
      });

    }
  }
}
</script>

<style scoped>

</style>