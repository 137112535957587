<template>

  <div class="pm-container mt-4 pb-5">
    <router-link class="btn btn-primary" to="/mediathek">Zurück</router-link>
    <hr>

    <h3>Neues Video einstellen</h3>
    <div class="row mb-3 mt-4">
      <label for="inputTitle" class="col-sm-2 col-form-label">Videotitel</label>
      <div class="col-sm-10">
        <input placeholder="Sie nahm diese Creme und dann passierte DAS" type="text" class="form-control"
               id="inputTitle" v-model="title">
      </div>
    </div>
    <div class="row mb-3">
      <label for="inputDescription" class="col-sm-2 col-form-label">Beschreibung</label>
      <div class="col-sm-10">
          <textarea placeholder="Warum eine Feuchtigkeitscreme echt wichtig ist, zeigt dieses Video.."
                    class="form-control" rows="5" id="inputDescription" v-model="description">

          </textarea>
      </div>
    </div>

    <div class="row mb-3">
      <label class="col-sm-2 col-form-label">Videodatei hochladen</label>
      <div class="col-sm-10">
        <FileUpload @uploadFinished="videoUploadFinished"></FileUpload>

      </div>


      <vue-plyr controls crossorigin>
        <video v-if="videoFileId" class="card-img" playsinline controls :data-poster="thumbnailLinkPreview">
          <source :src="videoLinkPreview" type="video/mp4" />
        </video>
      </vue-plyr>
    </div>

    <div class="row mb-3">
      <label for="thumbnailFile" class="col-sm-2 col-form-label">Vorschaubild hochladen</label>
      <div class="col-sm-10">
        <FileUpload id="thumbnailFile" @uploadFinished="thumbnailUploadFinished"></FileUpload>

        <img v-if="thumbnailFileId" class="mt-2" height="200" :src="thumbnailLinkPreview">
      </div>
    </div>

    <div class="row mb-3">
      <label for="lengthMin" class="col-sm-2 col-form-label">Videolänge (in Minuten)</label>
      <div class="col-sm-10">
        <input type="text" class="form-control" id="lengthMin" v-model="lengthMin">
      </div>
    </div>

    <div class="btn-group" role="group" aria-label="Basic example">
      <button @click="saveVideo" class="btn btn-primary">Speichern</button>
    </div>

  </div>
</template>

<script>
import FileUpload from "../../components/FileUpload";

export default {
  components: {FileUpload},
  data() {
    return {
      title: "",
      description: "",
      videoLink: '',
      thumbnailLink: '',
      lengthMin: 0,
      videoFileId: null,
      thumbnailFileId: null
    }
  },

  name: "VideoNew",

  computed: {
    thumbnailLinkPreview() {
      if (!this.thumbnailFileId)
        return null;

      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + this.thumbnailFileId);
    },

    videoLinkPreview() {
      if (!this.videoFileId)
        return null;

      return this.$filters.nonceLink(process.env.VUE_APP_API_URL + "/api/file/" + this.videoFileId);
    }
  },

  methods: {
    videoUploadFinished(file) {
      this.videoFileId = file.fileId
    },

    thumbnailUploadFinished(file) {
      this.thumbnailFileId = file.fileId
    },

    saveVideo() {


      let video = {
        title: this.title,
        description: this.description,
        lengthMin: parseInt(this.lengthMin),
        videoBlobId: this.videoFileId,
        thumbnailBlobId: this.thumbnailFileId
      }

      this.$store.dispatch("videos/store", video).then(() => {
        alert("Gespeichert!")
      })
    }
  }

}
</script>

<style scoped>

</style>